import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import styles from "../components/outhere.module.css"

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} noFooter noTop>
        <SEO title="OutHere → South North Studios" description="OutHere is now South North Studios"></SEO>
        <a style={{textDecoration: "none"}} href='/'>
         <div className={styles.wemoved}>
              <img src='/images/oh.png' alt='OutHere is now South North Studios' className={styles.ohLogo}/>
              <h1>we<br/>reorg<br/>ani<br/>sed</h1>
              <img src='/images/symbols.svg' alt='South North Studios' className={styles.snsLogo}/>
          </div>
        </a>
      </Layout>
    )
  }
}

export default RootIndex